<template>
  <div
    class="bg-white flex flex-col text-12px xxl:text-14px overflow-auto h-screen w-full pt-8"
  >
    <Breadcrumb />
    <Header :currentRoutes="getCurrentRoute" />
    <DataWrapper
      label="Daftar No. STT"
      class="w-full pt-6 text-12px xxl:text-14px"
    >
      <div class="text-gray-lp-500 pt-2">
        Jumlah maksimum yang dapat diproses adalah 50 STT.
      </div>
      {{ setErrorList }}
      {{ clearSttListValue() }}
      <div class="w-full">
        <div class="pt-4 w-3/4 inline-block relative">
          <multiselect
            v-model="sttListValue"
            placeholder="Masukan No. STT atau scan barcode"
            tag-placeholder="Press enter to add No. STT"
            label="name"
            track-by="id"
            :maxLength="50"
            :options="sttManualList"
            :multiple="true"
            :searchable="true"
            :taggable="true"
            :preserve-search="true"
            open-direction="bottom"
            isTextArea
            @keyup="onSearch($event)"
            :isError="isError"
          />
          <div
            class="absolute right-0 bottom-0 mr-2 mb-2 text-gray-lp-600 text-12px xxl:text-14px"
          >
            {{ sttListValue.length }}/50
          </div>
        </div>
        <div
          class="mt-4 text-red-lp-100 text-12px xxl:text-14px"
          v-show="isError"
        >
          {{ errorMessageLength }}/{{ sttListValue.length }} Data stt tidak
          ditemukan
        </div>
      </div>
      <div class="pt-4 flex flex-row">
        <button-fill
          custom="bg-white px-12 border border-red-lp-100 text-white mr-4"
          title="Tampilkan No. STT"
          :isDisabled="sttListValue.length === 0"
          :iconLeft="isRefresh()"
          @click="onGenerate"
        />
        <button-outline
          v-show="sttListValue.length !== 0"
          custom="bg-white px-12 border border-red-lp-100 text-red-lp-200 mr-4"
          title="Hapus Semua"
          @click="deleteAll"
        />
      </div>
    </DataWrapper>
    <div class="border-t my-6 border-gray-lp-400" />
    <DataWrapper
      v-if="isTableShow"
      label="Daftar STT Print"
      class="w-full pt-2 text-20px h-screen"
    >
      <div class="flex flex-row justify-between">
        <div class="flex flex-row py-4">
          <DataWrapper
            label="Nama Client / Partner"
            class="text-12px xxl:text-14px text-black-lp-100"
          >
            <div class="mt-2">{{ clientPartnerName }}</div>
          </DataWrapper>
          <DataWrapper
            label="Total Biaya"
            class="ml-20 text-12px xxl:text-14px text-black-lp-100"
          >
            <div
              v-if="sumTotalCharge !== sumTotalChargeAfterDiscount"
              :class="
                `${
                  sumTotalCharge
                    ? 'text-gray-lp-600 line-through'
                    : 'text-red-lp-100'
                } mt-2`
              "
            >
              {{ sumTotalCharge }}
            </div>
            <div v-if="sumTotalCharge" class="text-red-lp-100">
              {{ sumTotalChargeAfterDiscount }}
            </div>
          </DataWrapper>
        </div>
        <div class="flex flex-row py-4">
          <lp-button
            title="Print Thermal Resi"
            iconLeft="printer-white"
            @click="printThermal"
            textColor="white"
            :disabled="!!isRefresh()"
          />
          <lp-button
            :custom="
              `bg-white px-12 border mr-4 ${
                isRefresh()
                  ? 'border-gray-lp-500 text-gray-lp-500 cursor-not-allowed'
                  : 'border-red-lp-100 text-red-lp-200'
              }`
            "
            :iconLeft="
              `${
                isRefresh()
                  ? 'document-download-outline-grey'
                  : 'document-download-outline'
              }`
            "
            title="Print Thermal Resi to PDF"
            color="white"
            textColor="red-lp-300"
            customClass="border border-red-lp-100 ml-4"
            @click="printDoc('thermal-pdf')"
            :disabled="!!isRefresh()"
          />
          <lp-button
            :custom="
              `bg-white px-12 border mr-4 ${
                isRefresh()
                  ? 'border-gray-lp-500 text-gray-lp-500 cursor-not-allowed'
                  : 'border-red-lp-100 text-red-lp-200'
              }`
            "
            :iconLeft="
              `${
                isRefresh()
                  ? 'document-download-outline-grey'
                  : 'document-download-outline'
              }`
            "
            title="Print Basic/Save to PDF"
            @click="printDoc('basic')"
            color="white"
            textColor="red-lp-300"
            customClass="border border-red-lp-100 ml-4"
            :disabled="!!isRefresh()"
          />
        </div>
      </div>
      <TableV2
        :columns="columns"
        :data="listData"
        isRightFreeze
        :totalColumnFreeze="1"
        :hidePagination="true"
        :pagination="pagination"
      />
    </DataWrapper>
  </div>
</template>
<script>
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatPriceIDR from "@/app/infrastructures/misc/common-library/FormatPriceIDR";
import convertArrayToString from "@/app/infrastructures/misc/common-library/ConvertArrayToString";
import prefixAlgoEstimationShow from "@/app/infrastructures/misc/common-library/PrefixAlgoEstimationShow";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import Multiselect from "@/app/ui/components/multiselect-search-tag/index.vue";
import generateThermalPDF from "./modules/bulk-label-resi-thermal";
import generatePDF from "./modules/bulk-label-resi-pdf";
import JsBarcode from "jsbarcode";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ListSttController } from "@/app/ui/controllers/ListSttController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  payloadOfThermalData,
  downloadFilePrn
} from "@/app/infrastructures/misc/UtilsThermal";
import flatten from "lodash/flatten";
import { ListSttData } from "@/domain/entities/ListStt";

@Options({
  components: {
    EmptyState,
    Multiselect
  }
})
export default class BulkPrintResi extends Vue {
  mounted() {
    this.onRequest();
    this.sttListValue = [];
    this.cacheSttNo = [];
    ListSttController.resetSttList();
    ListSttController.setShowTable(false);
  }

  get getCurrentRoute() {
    return this.$route.meta.name;
  }

  cacheSttNo = [];
  isRefresh() {
    let isExist = true;
    for (let i = 0; i < this.sttListValue.length; i++) {
      const isAny = this.cacheSttNo.find(e => e.id === this.sttListValue[i].id);
      if (!isAny) {
        isExist = false;
        break;
      }
    }
    return this.cacheSttNo.length > 0 &&
      this.sttListValue.length > 0 &&
      (!isExist || this.sttListValue.length !== this.cacheSttNo.length)
      ? "refresh-white"
      : "";
  }

  // get client / partner name
  get clientPartnerName() {
    return AccountController.accountData.name;
  }

  isSttNumber(sttNumber) {
    return /^\d{1,2}LP\w+$/g.test(sttNumber.trim());
  }

  isLiloBag(bagNo) {
    return bagNo.trim().startsWith("TKP01-BAG-");
  }

  get listSttScan() {
    if (!this.sttListValue.length) {
      return [];
    }
    return this.sttListValue.filter(item => !this.isLiloBag(item.name));
  }

  get listBagScan() {
    if (!this.sttListValue.length) {
      return [];
    }
    return this.sttListValue.filter(item => this.isLiloBag(item.name));
  }

  // Generate Function
  get isTableShow() {
    return ListSttController.isTableShow;
  }

  // get list table stt
  getListSttTable() {
    ListSttController.getListStt({
      page: 1,
      limit: 50,
      destinationCityId: "",
      userType: "",
      bookedBy: "",
      bookedType: "",
      fromDate: "",
      endDate: "",
      sttNumber: convertArrayToString(this.listSttScan, "name")
    });
  }

  getListBagTable() {
    ListSttController.getListBag({
      bagNumber: convertArrayToString(this.listBagScan, "name")
    });
  }

  get sumTotalCharge() {
    let total = 0;
    this.listData?.map(e => {
      if (!this.estimationBound(e.sttShipmentId, e.sttClientId)) {
        total += Number(
          e.sttDocumentSurchargeRate +
            e.sttHeavyweightSurchargeRate +
            e.sttCommoditySurchargeRate +
            e.sttWoodpackingRate +
            e.sttShippingSurchargeRate +
            e.sttPublishRate +
            e.sttDestinationDistrictRate +
            e.sttOriginDistrictRate +
            e.sttTotalInsurance +
            e.sttCodFee
        );
      }
    });
    return total > 0 ? formatPriceIDR(total) : "-";
  }

  get sumTotalChargeAfterDiscount() {
    let total = 0;
    this.listData?.map(e => {
      if (!this.estimationBound(e.sttShipmentId, e.sttClientId)) {
        total += Number(e.sttTotalAmountAfterDiscount);
      }
    });
    return total > 0 ? formatPriceIDR(total) : "-";
  }

  clearSttListValue() {
    if (this.sttListValue.length === 0) {
      this.dataToBePrint = [];
      ListSttController.resetErrorMessage();
      ListSttController.resetSttList();
      ListSttController.setShowTable(false);
    }
  }
  dataToBePrint = [];

  // Table
  get listData() {
    if (this.listBagScan.length && !this.isRefresh()) {
      this.listBagData.forEach(item => {
        const currentIdx = ListSttController.sttList?.data.findIndex(
          exist => exist.sttNo === item.bagCode
        );
        if (currentIdx === -1) {
          return ListSttController.sttList?.data.push(
            new ListSttData({
              sttId: item.bagId,
              sttNo: item.bagCode,
              sttTotalPiece: item.bagTotalPiece,
              sttChargeableWeight: item.bagTotalGrossWeight,
              sttRecipientAddress: "-",
              sttSenderName: "-",
              sttRecipientName: "-",
              sttIsInsurance: false,
              sttIsCod: false,
              sttShipmentId: ""
            })
          );
        }
      });
    }
    return ListSttController.sttList?.data;
  }
  get listSttData() {
    return ListSttController.sttList?.data;
  }
  get listBagData() {
    return ListSttController.bagList?.data;
  }
  get pagination() {
    return ListSttController.sttList?.pagination;
  }
  columns = [
    {
      name: "No",
      styleHead: "w-10 text-left",
      styleBody: "text-center",
      render: (item, index) => {
        return `<div class='text-left text-black-lp-300'>${index + 1}</div>`;
      }
    },
    {
      name: "No. STT",
      styleHead: "w-44 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: item => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap'>${item.sttNo}</div>`;
      }
    },
    {
      name: "No. Referensi",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: item => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap'>${
          !item.sttShipmentId ? "-" : item.sttShipmentId
        }</div>`;
      }
    },
    {
      name: "Total Koli",
      styleHead: "w-32 text-left whitespace-no-wrap",
      render: item => {
        return `<div class='flex text-black-lp-300 text-center'>${item.sttTotalPiece}</div>`;
      }
    },
    {
      name: "Total Berat",
      styleHead: "w-32 text-left",
      render: item => {
        return `<div class='flex text-black-lp-300'>${convertDecimalWithComma(
          item.sttChargeableWeight,
          2
        )} Kg</div>`;
      }
    },
    {
      name: "Alamat Tujuan",
      styleHead: "w-64 text-left whitespace-no-wrap",
      render: item => {
        return `<div class="overflow-ellipsis text-black-lp-300">${ellipsisString(
          item.sttRecipientAddress,
          30
        )}</div>`;
      }
    },
    {
      name: "Pengirim",
      styleHead: "w-44 text-left",
      render: item => {
        return `<div class='flex text-black-lp-300'>${ellipsisString(
          item.sttSenderName,
          45
        )}</div>`;
      }
    },
    {
      name: "Penerima",
      styleHead: "w-68 text-left",
      render: item => {
        return `<div class="text-black-lp-300 flex">${ellipsisString(
          item.sttRecipientName,
          78
        )}</div>`;
      }
    },
    {
      name: "Asuransi",
      styleHead: "w-24 text-left",
      render: item => {
        return `<div class="text-black-lp-300 flex">${
          item.sttIsInsurance ? "Yes" : "No"
        }</div>`;
      }
    },
    {
      name: "COD",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: item => {
        return `<div class="text-black-lp-300 flex">${
          item.sttIsCod ? "Yes" : "No"
        }</div>`;
      }
    },
    {
      name: "Biaya Pengiriman",
      styleHead: "w-56 text-left whitespace-no-wrap",
      render: item => {
        const feeDelivery = this.estimationBound(
          item.sttShipmentId,
          item.sttClientId
        )
          ? "-"
          : this.shippingCost(item);
        return `<div class="text-black-lp-300 flex">${feeDelivery}</div>`;
      }
    },
    {
      name: "Surcharge",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: item => {
        const surcharge = this.estimationBound(
          item.sttShipmentId,
          item.sttClientId
        )
          ? "-"
          : this.amountSurcharge(item);
        return `<div class="text-black-lp-300 flex">${surcharge}</div>`;
      }
    },
    {
      name: "Biaya Asuransi",
      styleHead: "w-40 text-left",
      render: item => {
        const insurance =
          item.sttTotalInsurance === 0
            ? "-"
            : formatPriceIDR(item.sttTotalInsurance);
        return `<div class="text-black-lp-300 flex">${insurance}</div>`;
      }
    },
    {
      name: "Total Tarif",
      styleHead: "w-40 text-left",
      render: item => {
        return this.totalAmount(item);
      }
    }
  ];

  // handling
  get isLoading() {
    return ListSttController.isLoading;
  }
  get isError() {
    return ListSttController.isError;
  }
  get errorCause() {
    return ListSttController.isErrorCause;
  }
  get errorMessageLength() {
    return ListSttController.errorMessageList.split(",").length;
  }
  get errorMessageList() {
    return ListSttController.errorMessageList;
  }

  // Fetch No. STT
  sttListValue = [];
  get setErrorList() {
    const error = ListSttController.errorMessageList;
    this.sttListValue = this.sttListValue.map(item => {
      return { ...item, isError: error.includes(item.name) };
    });
    return "";
  }

  resetBagList() {
    ListSttController.resetBagList();
  }

  resetResiListData() {
    ListSttController.resetResiListData();
  }

  onGenerate() {
    this.resetBagList(); // reset listbagData
    this.resetResiListData(); // reset resiListData
    if (this.listSttScan.length) {
      this.getListSttTable();
    }
    if (this.listBagScan.length) {
      this.getListBagTable();
    }
    this.cacheSttNo = this.sttListValue;
    ListSttController.resetErrorMessage();
  }
  onRequest() {
    ShipmentBookingController.fetchShipmentBooking();
  }

  get sttManualList() {
    return ShipmentBookingController.shipmentBookingData.data.map(e => ({
      name: e.sttNo,
      id: e.sttId,
      isError: false
    }));
  }

  shippingCost(data) {
    return formatPriceIDR(
      data.sttShippingSurchargeRate +
        data.sttPublishRate +
        data.sttDestinationDistrictRate +
        data.sttOriginDistrictRate
    );
  }

  amountSurcharge(data) {
    return formatPriceIDR(
      data.sttDocumentSurchargeRate +
        data.sttHeavyweightSurchargeRate +
        data.sttCommoditySurchargeRate +
        data.sttWoodpackingRate +
        data.sttCodFee
    );
  }

  totalAmount(data) {
    if (this.estimationBound(data.sttShipmentId, data.sttClientId)) {
      return `<div class="text-red-lp-100 flex font-semibold">-</div>`;
    }
    if (data.sttIsPromo) {
      return `<div class="text-gray-lp-600 flex font-semibold">
        <s>${this.totalAmountWithoutDiscount(data)}</s>
      </div>
      <div class="text-red-lp-100 flex font-semibold">
        ${formatPriceIDR(data.sttTotalAmountAfterDiscount)}
      </div>`;
    }
    return `<div class="text-red-lp-100 flex font-semibold">${this.totalAmountWithoutDiscount(data)}</div>`;
  }

  totalAmountWithoutDiscount(data) {
    return formatPriceIDR(
      data.sttDocumentSurchargeRate +
        data.sttHeavyweightSurchargeRate +
        data.sttCommoditySurchargeRate +
        data.sttWoodpackingRate +
        data.sttShippingSurchargeRate +
        data.sttPublishRate +
        data.sttDestinationDistrictRate +
        data.sttOriginDistrictRate +
        data.sttTotalInsurance +
        data.sttCodFee
    );
  }

  get isLoadingSttManual() {
    return ShipmentBookingController.isLoading;
  }

  onSearch(e) {
    ShipmentBookingController.searchAction(e.target?.value);
  }

  deleteAll() {
    this.sttListValue = [];
    this.cacheSttNo = [];
    this.dataToBePrint = [];
    ListSttController.resetErrorMessage();
    ListSttController.resetSttList();
    ListSttController.setShowTable(false);
  }

  async printDoc(type) {
    const listSttPieces = [];

    ListSttController.resiListData.forEach((item, index) => {
      if (item) {
        const barcode = document.createElement("img");
        const vendorBarcode = document.createElement("img");
        JsBarcode(barcode, item.sttNo, {
          textAlign: "left",
          displayValue: false,
          fontSize: 16
        });
        if (item.sttVendorRefferenceNo) {
          // vendor ref code
          JsBarcode(vendorBarcode, item.sttVendorRefferenceNo, {
            textAlign: "left",
            displayValue: false,
            fontSize: 16
          });
        }

        item.sttPieces.forEach(e => {
          listSttPieces.push({
            ...e,
            ...item,
            barcode: barcode.src,
            vendorRefCode: vendorBarcode.src
          });
        });

        barcode.remove();
        vendorBarcode.remove();
      }
    });

    if (listSttPieces.length || this.listBagData.length) {
      new Promise(function(resolve) {
        MainAppController.showLoading();
        setTimeout(resolve.bind(null, null), 1);
      }).then(() => {
        const accountType = AccountController.accountData.account_type;
        if (type === "thermal-pdf") {
          generateThermalPDF(accountType, listSttPieces, this.listBagData);
        } else {
          generatePDF(accountType, listSttPieces, this.listBagData);
        }

        MainAppController.closeLoading();
      });
    }
  }

  // print thermal
  get resiListData() {
    const listSttPieces = [];
    ListSttController.resiListData.forEach(item => {
      item.sttPieces.forEach(e => listSttPieces.push(e));
    });
    return listSttPieces;
  }

  printThermal() {
    const payloadPushed = [];
    const accountType = AccountController.accountData.account_type;
    ListSttController.resiListData.forEach((item, index) => {
      if (item) {
        payloadPushed.push(
          payloadOfThermalData(item, accountType).map(e => ({
            ...e,
            sttIsCodRetail:
              !item.sttShipmentId &&
              !item.sttClientId &&
              (accountType === "partner" || accountType === "internal") &&
              e.sttIsCod
          }))
        );

        if (index === ListSttController.resiListData.length - 1) {
          new Promise(resolve => {
            MainAppController.showLoading();
            setTimeout(resolve.bind(null, null), 1);
          }).then(() => {
            const payloadResult = flatten(payloadPushed);
            downloadFilePrn(payloadResult, "id", true);
            MainAppController.closeLoading();
          });
        }
      }
    });
  }

  get accountType() {
    return AccountController.accountData.account_type;
  }

  estimationBound(shipmentId, clientId) {
    if (
      shipmentId &&
      !prefixAlgoEstimationShow(shipmentId) &&
      this.accountType === "partner"
    ) {
      // shipment type by pos
      return true;
    } else if (
      !shipmentId &&
      clientId === 0 &&
      this.accountType === "partner"
    ) {
      // manual type by pos
      return false;
    } else if (!shipmentId && clientId === 0 && this.accountType === "client") {
      // manual type by client
      return true;
    } else if (
      !shipmentId &&
      clientId !== 0 &&
      this.accountType === "partner"
    ) {
      // client type by pos
      return true;
    } else if (
      !shipmentId &&
      clientId !== 0 &&
      this.accountType === "internal"
    ) {
      // client type by internal
      return true;
    } else
      return !shipmentId && clientId !== 0 && this.accountType === "client";
  }
}
</script>
